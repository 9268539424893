export default function ErrorPage({ header, message }: { header: string; message: string }) {
  return (
    <div className="h-[100dvh] flex flex-col justify-center items-center">
      <h1 className="font-semibold text-3xl mb-4">{header}</h1>
      <p className="mx-4 text-center">
        {message.split("\n").map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
}
